import styled from "@emotion/styled";

type TImageBackgroundProps = {
  src: string;
  children: React.ReactNode;
};

const ImageBackground = ({ children, src }: TImageBackgroundProps) => {
  return <StyledBox src={src}>{children}</StyledBox>;
};

const StyledBox = styled.div<{ src: string }>`
  background-image: url(${({ src }) => src});

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default ImageBackground;
