import styled from '@emotion/styled'
import RecoveryForm from './Form'
import Typography from '../Typography'

const Recovery = () => {
  return (
    <StyledSection id="section_recovery">
      <article>
        <Typography.Title>
          <Typography.HighlightText>Recovering</Typography.HighlightText> A Security Key
        </Typography.Title>
        <Typography.Paragraph>Enter the security key issued to you by service.</Typography.Paragraph>
      </article>
      <RecoveryForm />
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 64px;

  padding: 120px 0;

  text-align: center;

  ${`@media (max-width: ${1024}px)`} {
    gap: 32px;
  }
`

export default Recovery
