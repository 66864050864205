import styled from "@emotion/styled";

export type TProcedureCardProps = {
  title: React.ReactNode;
  text: React.ReactNode;
  iconSrc: string;
  index: number;
};

const pad = (num: number) => `0${num}`.slice(-2);

const ProcedureCard = ({
  title,
  text,
  iconSrc,
  index,
}: TProcedureCardProps) => {
  return (
    <StyledArticle>
      <StyledIndex>{pad(index)}</StyledIndex>
      <StyledIcon src={iconSrc} />
      <StyledTitle>{title}</StyledTitle>
      <StyledParagraph>{text}</StyledParagraph>
    </StyledArticle>
  );
};

const StyledArticle = styled.article`
  position: relative;

  padding: 78px 36px 56px 36px;

  background-color: rgba(17, 17, 17, 0.6);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #475569;
  border-radius: 8px;
`;

const StyledIndex = styled.span`
  position: absolute;
  right: 21px;
  top: 21px;

  padding: 14px;

  font-weight: 500;
  font-size: 21px;

  background: linear-gradient(180deg, #8496b0 0%, rgba(100, 116, 139, 0) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  border-bottom: 2px solid #64748b;
  border-radius: 50%;
`;

const StyledIcon = styled.img`
  margin-bottom: 36px;
`;

const StyledTitle = styled.h6`
  margin-bottom: 24px;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.25;

  letter-spacing: -0.02em;
`;

const StyledParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;

  letter-spacing: -0.02em;

  color: #f8fafc;
`;

export default ProcedureCard;
