import { useCallback, useContext, useState } from "react";
import { SocketClientContext } from "../../contexts/SocketClient";
import { createDeferred } from "../../utils/createDeferred";
import { onSuccessLogger } from "./logger";
import { PayloadBase } from "./types";

type UseSocketResult<TResponse, TRequest> = [TResponse | undefined, any];

// timeout for after socket request ms
const timeoutPeriod = 7500;

export const useAsyncSocket = <ResponseType, RequestType>(): UseSocketResult<
  ResponseType,
  RequestType
> => {
  const socketClient = useContext(SocketClientContext);
  const [webSocketResponse, setWebSocketResponse] = useState<ResponseType>();

  const fetcher = useCallback(
    async (param: RequestType & PayloadBase) => {
      if (!socketClient) return;

      let timeoutKey: number;
      const [promise, resolve, reject] = createDeferred<ResponseType>();

      const handler = (payload: MessageEvent) => {
        const { data } = payload;
        const parsed = JSON.parse(data) as ResponseType & PayloadBase;

        if (
          parsed.handle === param.handle &&
          String(parsed.tr_code) === String(param.tr_code)
        ) {
          if (process.env.NODE_ENV === "development") onSuccessLogger(parsed);

          window.clearTimeout(timeoutKey);
          removeListener();
          resolve(parsed);
          setWebSocketResponse(parsed);
        }
      };
      const removeListener = () =>
        socketClient.removeEventListener("message", handler);

      socketClient.addEventListener("message", handler);
      socketClient.send(JSON.stringify(param));

      timeoutKey = window.setTimeout(() => {
        removeListener();
        reject();
      }, timeoutPeriod);

      return promise;
    },
    [socketClient]
  );

  return [webSocketResponse, fetcher];
};
