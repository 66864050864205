import styled from '@emotion/styled'

const Appbar = () => {
  return (
    <StyledHeader>
      <StyledContainer>
        <StyledButton
          type="button"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }}
        >
          <img src="/img/logo.png" alt="service logo" />
        </StyledButton>
        <StyledNav>
          <StyledUnorderedList>
            <li>
              <StyledAnchor href="#section_procedure">How to proceed</StyledAnchor>
            </li>
            <li>
              <StyledAnchor href="#section_precautions">Precautions</StyledAnchor>
            </li>
            <li>
              <StyledAnchor href="#section_recovery">Recovering</StyledAnchor>
            </li>
          </StyledUnorderedList>
        </StyledNav>
        {/* <StyledOutLink href="https://n-pik.com" target="_blank">
          Go to service
        </StyledOutLink> */}
      </StyledContainer>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  padding: 27px;

  backdrop-filter: blur(20px);
`

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
`

const StyledNav = styled.nav`
  ${`@media (max-width: ${1024}px)`} {
    display: none;
  }
`

const StyledButton = styled.button`
  background: none;
  border: none;
  outline: none;

  cursor: pointer;
`

const StyledUnorderedList = styled.ul`
  display: flex;

  gap: 24px;

  margin: 0;
  padding: 0;

  list-style: none;
`

const StyledAnchor = styled.a`
  text-decoration: none;

  color: white;
`

const StyledOutLink = styled.a`
  padding: 16px 24px;

  font-weight: 500;
  font-size: 14px;

  background: linear-gradient(90deg, #00dc82 0%, #36e4da 100%);
  background-clip: text;
  text-fill-color: transparent;

  border: 1px solid #00dc82;
  border-radius: 25px;

  ${`@media (max-width: ${1024}px)`} {
    display: none;
  }
`

export default Appbar
