import { useCallback, useMemo } from 'react'
import { useAsyncSocket } from '../../hooks/useAsyncSocket'
import { queryKeys } from '../../constants/queryKeys'

export enum SiteType {
  INNODEX = '1',
  NHUB = '2',
  NVIRWORLD = '3',
  // NPIK = '4',
  // NBIX = '5',
}

type Request = {
  site_type: SiteType
  pri_key: string
}

type Response = {
  eth_pkey: string
  sol_pkey: string
  user_id: string
  err_code: string
}

// FIXME: async socket 지우고 base type 정리
export const useRecovery = () => {
  const [recoveryResponse, request] = useAsyncSocket<Response, Request>()

  const response = useMemo(() => {
    if (!recoveryResponse) return null

    return {
      ...recoveryResponse,
    }
  }, [recoveryResponse])

  const fetcher = useCallback(
    ({ site_type, pri_key }: Request) => {
      request({
        tr_code: '4300',
        site_type,
        pri_key,
        handle: queryKeys.WALLET_RECOVERY,
      })
    },
    [request]
  )

  return [response, fetcher] as const
}
