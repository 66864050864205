import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Checkbox,
  Box,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import { IconApply } from "../@vectors";

interface RecoveryModalProps {
  isOpen: boolean;
  onClose: () => void;
  isChecked: boolean;
  setIsChecked: (e: boolean) => void;
  fetchWalletRecovery: () => void;
}

const RecoveyConfirmModal = ({
  isOpen,
  onClose,
  isChecked,
  setIsChecked,
  fetchWalletRecovery,
}: RecoveryModalProps) => {
  const [isLargeSize] = useMediaQuery("(min-width: 1024px)");

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={isLargeSize ? "460px" : "300px"}
        padding={isLargeSize ? "36px" : "24px"}
        backgroundColor={"#171B24"}
        borderRadius={"12px"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={isLargeSize ? "24px" : "16px"}
        verticalAlign={"center"}
      >
        <div>
          <IconApply />
        </div>
        <ModalHeader
          fontSize={isLargeSize ? "24px" : "16px"}
          textAlign={"center"}
        >
          Would you like to
          <br /> apply for this service?
        </ModalHeader>
        <ModalBody>
          <Box display={"flex"} gap={"9px"}>
            <Checkbox
              colorScheme="green"
              w={"16px"}
              h={"16px"}
              mt={"5px"}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <Box fontSize={isLargeSize ? "16px" : "12px"} color={"#94A3B8"}>
              I have read all the caveats before
              <br /> applying for this service.
            </Box>
          </Box>
        </ModalBody>
        <Button
          _hover={{
            background:
              "var(--wp-primary, linear-gradient(90deg, #00DC82 0%, #36E4DA 100%))",
            color: "#000",
          }}
          isDisabled={!isChecked}
          border={"1px solid  var(--wp-primary, #00DC82)"}
          borderRadius={"99px"}
          background={"transparent"}
          color={"#00DC82"}
          padding={"12px 24px"}
          w={"100%"}
          onClick={() => fetchWalletRecovery()}
        >
          Confirm
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default RecoveyConfirmModal;
