import styled from '@emotion/styled'
import Typography from '../Typography'

const Precautions = () => {
  return (
    <StyledSection id="section_precautions">
      <StyledArticle>
        <Typography.Title>Precautions</Typography.Title>
        <Typography.Paragraph>Please read the following precautions before using the service.</Typography.Paragraph>
        <StyledOrderedList>
          <StyledListItem>This service internally decrypts the private wallet's security key, allowing external blockchain wallet services such as MetaMask to control it directly via 'Import Wallet'.</StyledListItem>
          <StyledListItem>This service helps users safely transfer their assets to an external wallet if the service stops operating or becomes inaccessible.</StyledListItem>
          <StyledListItem>
            The service will continuously monitor its own operations and activate the security key recovery mode under two conditions: if no traffic is detected for 72 hours or if the server stops responding. This process bypasses the account
            verification procedure.
          </StyledListItem>
          <StyledListItem>You can use this service through the account verification process if it is operating normally.</StyledListItem>
          <StyledListItem>Only accounts that are registered can use this service.</StyledListItem>
          <StyledListItem>Users who have not completed balance synchronization and S2k L2 system processing will no longer be able to use this service.</StyledListItem>
          <StyledListItem>When users apply for the service, accounts registered for the service will be automatically unsubscribed, and users will no longer be able to access the service under the same account.</StyledListItem>
          <StyledListItem>Before applying for this service, please ensure that you back up your personal information, including service membership details. Once member information is deleted, it cannot be recovered.</StyledListItem>
          <StyledListItem>Once applied, the service cannot be canceled under any circumstances. Please apply carefully, as we are not responsible for any issues arising from membership withdrawal.</StyledListItem>
          <StyledListItem>Please note that this service, authorized solely for third-party monitoring and security key recovery, is not directly affiliated with any of the provided services.</StyledListItem>
        </StyledOrderedList>
      </StyledArticle>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  padding-top: 150px;
  padding-bottom: 190px;
`

const StyledArticle = styled.article`
  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 78px 115px;

  background-color: #111111;
  border-radius: 8px;

  ${`@media (max-width: ${1024}px)`} {
    padding: 48px 24px;
  }
`

const StyledOrderedList = styled.ol`
  margin-top: 44px;
  margin-bottom: 0;

  padding: 0;

  list-style-position: inside;
`

const StyledListItem = styled.li`
  margin-bottom: 24px;

  font-weight: 400;
  font-size: 16px;

  line-height: 1.25;

  color: #94a3b8;

  &:last-of-type {
    margin-bottom: 0;
  }
`

export default Precautions
