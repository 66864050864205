type TDeferred<T> = {
  resolve: (payload: T) => void;
  reject: () => void;
};

export const createDeferred = <ReturnValue>() => {
  const ref = {} as TDeferred<ReturnValue>;

  const promise = new Promise<ReturnValue>((res, rej) => {
    ref.resolve = res;
    ref.reject = rej;
  });

  return [promise, ref.resolve, ref.reject] as const;
};
