import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Box,
} from "@chakra-ui/react";
import { IconNotAccount } from "../@vectors";
import { useMediaQuery } from "@chakra-ui/react";
interface RecoveryModalProps {
  isOpen: boolean;
  onClose: () => void;
  errorCode?: string;
  errorText: string;
}

const RecoveryErrorModal = ({
  isOpen,
  onClose,
  errorCode,
  errorText,
}: RecoveryModalProps) => {
  const [isLargeSize] = useMediaQuery("(min-width: 1024px)");

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={isLargeSize ? "460px" : "300px"}
        padding={isLargeSize ? "36px" : "24px"}
        backgroundColor={"#171B24"}
        borderRadius={"12px"}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={isLargeSize ? "10px" : "7px"}
        verticalAlign={"center"}
      >
        <div>
          <IconNotAccount />
        </div>
        <ModalHeader
          fontSize={isLargeSize ? "24px" : "16px"}
          textAlign={"center"}
        >
          Error: {errorCode}
        </ModalHeader>
        <ModalBody>
          <Box
            display={"flex"}
            gap={"9px"}
            textAlign={"center"}
            fontSize={isLargeSize ? "16px" : "12px"}
            color={"#94A3B8"}
          >
            {errorText}
          </Box>
        </ModalBody>
        <Button
          _hover={{
            background:
              "var(--wp-primary, linear-gradient(90deg, #00DC82 0%, #36E4DA 100%))",
            color: "#000",
          }}
          mt={"12px"}
          border={"1px solid  var(--wp-primary, #00DC82)"}
          borderRadius={"99px"}
          background={"transparent"}
          color={"#00DC82"}
          padding={"12px 24px"}
          w={"100%"}
          onClick={() => onClose()}
        >
          Confirm
        </Button>
      </ModalContent>
    </Modal>
  );
};

export default RecoveryErrorModal;
