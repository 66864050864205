import styled from "@emotion/styled";

const Title = styled.h4`
  margin: 0 0 20px 0;

  font-weight: 500;
  font-size: 40px;

  line-height: 1;

  ${`@media (max-width: ${1024}px)`} {
    white-space: nowrap;
    font-size: 24px;
  }
`;

const Paragraph = styled.h6`
  margin: 0;

  font-weight: 400;
  font-size: 21px;

  color: #cbd5e1;

  ${`@media (max-width: ${1024}px)`} {
    font-size: 14px;
  }
`;

const HighlightText = styled.span`
  background: linear-gradient(90deg, #00dc82 0%, #36e4da 100%);
  background-clip: text;

  -webkit-text-fill-color: transparent;
`;

export default {
  Title,
  Paragraph: Paragraph,
  HighlightText,
} as const;
