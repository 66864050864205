import styled from "@emotion/styled";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledText>
        © COPYRIGHT 2023. WALLET RECOVERY. ALL RIGHTS RESERVED.
      </StyledText>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  border-top: 1px solid #1e293b;
`;

const StyledText = styled.p`
  margin: 0;
  padding: 24px 0;

  font-weight: 400;
  font-size: 16px;

  color: #475569;

  ${`@media (max-width: ${1024}px)`} {
    text-align: center;
    font-size: 14px;
  }
`;

export default Footer;
