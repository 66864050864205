import styled from '@emotion/styled'
import Typography from '../Typography'

const Banner = () => {
  return (
    <StyledSection>
      <StyledWrapper>
        <StyledSubtitle>Move your assets securely</StyledSubtitle>
        <StyledTitle>
          Provide a secure
          <br />
          wallet recovery service
        </StyledTitle>
        <StyledParagraph>
          You can apply to decrypt your private wallet's security key issued by service.
          <br />
          Be sure to read the caveats below before applying.
        </StyledParagraph>
        <StyledAnchor href="#section_recovery">Go to check</StyledAnchor>
      </StyledWrapper>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 275px 0;
`

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`

const StyledSubtitle = styled.span`
  font-weight: 500;
  font-size: 16px;

  background: linear-gradient(90deg, #00dc82 0%, #36e4da 100%);
  background-clip: text;
  text-fill-color: transparent;
`

const StyledTitle = styled.h1`
  margin: 36px 0;

  font-weight: 600;
  font-size: 64px;

  line-height: 1.1;

  text-transform: capitalize;

  ${`@media (max-width: ${1024}px)`} {
    font-size: 32px;
  }
`

const StyledParagraph = styled(Typography.Paragraph)`
  line-height: 1.4;

  ${`@media (max-width: ${1024}px)`} {
    font-size: 14px;
    line-height: 1.7;
  }
`

const StyledAnchor = styled.a`
  display: inline-block;

  margin-top: 72px;
  padding: 16px 32px;

  background: linear-gradient(90deg, #00dc82 0%, #36e4da 100%);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);

  border-radius: 45px;

  color: black;

  line-height: 1;

  font-weight: 600;
  font-size: 16px;

  text-decoration: none;
`

export default Banner
