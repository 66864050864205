import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useCallback, useEffect, useState } from 'react'
import { IconCopy, IconTimer } from '../@vectors'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useInterval } from 'usehooks-ts'
import { useCopyToClipboard } from 'usehooks-ts'
import { SiteType, useRecovery } from '../../hooks/mutations/useRecovery'
import RecoveryErrorModal from './RecoveryErrorModal'
import RecoveyConfirmModal from './RecoveryConfirmModal'
import errorMap from '../../constants/errorMap'

const RecoveryForm = () => {
  const { isOpen: showConfirmRecovery, onOpen: onOpenRecovery, onClose: onCloseRecovery } = useDisclosure()
  const { isOpen: showRecoveryError, onOpen: onOpenRecoveyError, onClose: onCloseRecoveyError } = useDisclosure()
  const toast = useToast()

  const [, copy] = useCopyToClipboard()
  const [isFocused, setIsFocused] = useState(false)
  const [isSOLFocused, setIsSOLFocused] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [isActiveRecovery, setIsActiveRecovery] = useState(false)
  const [siteType, setSiteType] = useState<SiteType>(SiteType.INNODEX)
  const [userInput, setUserInput] = useState('')
  const [errorText, setErrorText] = useState('')
  const [recoveryCount, setRecoveryCount] = useState(30)

  const [recoveryResponse, fetchRecovery] = useRecovery()

  const onClickPrivateInputButton = () => {
    if (!isActiveRecovery) {
      onOpenRecovery()
    }
    if (isActiveRecovery) {
      setUserInput('')
      setIsActiveRecovery(false)
    }
  }

  const onClickCopyPrivateKey = (value: string) => {
    copy(value)
    toast({
      status: 'success',
      description: 'copied success',
      duration: 2000,
    })
  }

  const exportETHPrivateKey = useCallback((ethPrivateKey: string) => {
    let fileName = 'private-eth-key.txt'
    let key = ethPrivateKey
    const element = document.createElement('a')
    const file = new Blob([key], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }, [])

  const exportSOLPrivateKey = useCallback((solPrivateKey: string) => {
    let fileName = 'private-sol-key.txt'
    let key = solPrivateKey
    const element = document.createElement('a')
    const file = new Blob([key], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = fileName
    document.body.appendChild(element)
    element.click()
  }, [])

  const onClickFetchWalletRecovery = useCallback(() => {
    if (userInput.trim() === '') {
      toast({
        status: 'error',
        description: 'check your security key',
        duration: 2000,
      })
      onCloseRecovery()
      setIsChecked(false)
      return
    }
    fetchRecovery({ site_type: siteType, pri_key: userInput })
    onCloseRecovery()
  }, [fetchRecovery, onCloseRecovery, siteType, toast, userInput])

  // RecoveryResponse 에러코드별 팝업
  useEffect(() => {
    if (recoveryResponse) {
      // if (recoveryResponse.err_code === '10010') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('There is no information on the item.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '11011') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('There was a problem changing your service membership information, please try again later or contact support.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '18004' || recoveryResponse.err_code === '18006' || recoveryResponse.err_code === '18007') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('You cannot view your wallet information with the entered security key. Please check your security key again.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19001') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('The entered security key format is invalid. Please check your secret key and try again.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19322') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('This is a site that is currently in service. The process is terminating as it did not receive a proper response for member withdrawal.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19323') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('The site code is incorrect.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19705') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('Due to poor communication or system issues, there was a problem retrieving your membership information. Please try again later. If the problem persists, please contact our customer support team.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19756') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('You have outstanding coin orders on the service order book. Please cancel all unfilled coin orders.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19757') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('You have coins whose balance synchronization has not been completed. You can leave the service after balance synchronization is complete.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19758') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('There are unprocessed coin transfers on the S2k L2 system. This service will be available after the coin transfer on the S2k L2 system is complete.')
      //   onOpenRecoveyError()
      //   return
      // }
      // if (recoveryResponse.err_code === '19999') {
      //   setUserInput('')
      //   setIsChecked(false)
      //   setErrorText('Unavailable due to a system error. Please contact customer support.')
      //   onOpenRecoveyError()
      //   return
      // }

      switch (recoveryResponse.err_code) {
        case '0':
          if (recoveryResponse.eth_pkey && recoveryResponse.sol_pkey) {
            exportETHPrivateKey(recoveryResponse.eth_pkey)
            exportSOLPrivateKey(recoveryResponse.sol_pkey)
            setIsActiveRecovery(true)
            alert('Your private key has been saved to that device. Be sure to check your download file.')
          } else if (recoveryResponse.eth_pkey) {
            exportETHPrivateKey(recoveryResponse.eth_pkey)
            setIsActiveRecovery(true)
            alert('Your private key has been saved to that device. Be sure to check your download file.')
          }
          return

        default:
          setUserInput('')
          setIsChecked(false)
          setErrorText(errorMap[Number(recoveryResponse.err_code)])
          onOpenRecoveyError()
          break
      }
    }
  }, [exportETHPrivateKey, exportSOLPrivateKey, onOpenRecoveyError, recoveryResponse])

  // Response시 30초 타이머
  const DELAY = 1000
  useInterval(
    () => {
      if (recoveryCount <= 0) {
        setIsActiveRecovery(false)
        setUserInput('')
        return
      }
      if (isActiveRecovery) {
        setRecoveryCount(recoveryCount - 1)
      }
    },
    isActiveRecovery ? DELAY : null
  )

  return (
    <>
      {/* privateKey Input */}
      <StyledForm
        active={isFocused}
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <div style={{ position: 'relative' }}>
          <StyledSelect value={siteType} onChange={(e) => setSiteType(e.target.value as SiteType)}>
            <option value={SiteType.INNODEX}>INNODEX</option>
            <option value={SiteType.NHUB}>N-HUB</option>
            <option value={SiteType.NVIRWORLD}>NVIRWORLD</option>
          </StyledSelect>
          <StyledDropdownArrowIcon src="/img/dropdown_arrow.svg" alt="" />
        </div>
        <StyledIconWrapper>
          <StyledIcon src="/img/ic_outline-security.png" active={!isFocused} />
          <StyledIcon src="/img/ic_outline-security-primary.png" active={isFocused} />
        </StyledIconWrapper>
        <StyledInput
          active={isActiveRecovery}
          name="privateKey"
          value={isActiveRecovery ? recoveryResponse?.eth_pkey : userInput}
          onChange={(e) => {
            setUserInput(e.target.value)
          }}
          onFocus={() => {
            setIsFocused(true)
          }}
          onBlur={() => {
            setIsFocused(false)
          }}
          placeholder="Enter the security key for your authenticated service account."
          autoComplete="off"
        />
        {isActiveRecovery && recoveryResponse && (
          <Box onClick={() => onClickCopyPrivateKey(recoveryResponse.eth_pkey)} cursor={'pointer'}>
            <IconCopy />
          </Box>
        )}
        <StyledButton onClick={onClickPrivateInputButton}>{isActiveRecovery ? 'Confirm' : 'Apply'}</StyledButton>
      </StyledForm>
      <StyledMobileButton onClick={onClickPrivateInputButton}>{isActiveRecovery ? 'Confirm' : 'Apply'}</StyledMobileButton>

      {/* 응답시 sol pkey */}
      {isActiveRecovery && (
        <StyledForm active={isSOLFocused}>
          <StyledIconWrapper>
            <StyledIcon src="/img/ic_outline-security.png" active={!isSOLFocused} />
            <StyledIcon src="/img/ic_outline-security-primary.png" active={isSOLFocused} />
          </StyledIconWrapper>
          <StyledInput
            active={isActiveRecovery}
            name="privateKey"
            value={recoveryResponse?.sol_pkey}
            onFocus={() => {
              setIsSOLFocused(true)
            }}
            onBlur={() => {
              setIsSOLFocused(false)
            }}
          />
          {recoveryResponse && (
            <Box onClick={() => onClickCopyPrivateKey(recoveryResponse.sol_pkey)} cursor={'pointer'}>
              <IconCopy />
            </Box>
          )}
        </StyledForm>
      )}

      {isActiveRecovery && (
        <StyledDescContainer>
          <StyledPrivateKeyDesc>
            Your private key has been automatically downloaded to your device.
            <br />
            Keep your decrypted private key safe and never disclose it to anyone.
          </StyledPrivateKeyDesc>
          <StyledTimer>
            <IconTimer />
            {recoveryCount}
          </StyledTimer>
        </StyledDescContainer>
      )}

      {/* Confirm Modal */}
      <RecoveyConfirmModal isOpen={showConfirmRecovery} onClose={onCloseRecovery} isChecked={isChecked} setIsChecked={setIsChecked} fetchWalletRecovery={onClickFetchWalletRecovery} />

      {/* checkSecurity */}
      <RecoveryErrorModal isOpen={showRecoveryError} onClose={onCloseRecoveyError} errorCode={recoveryResponse?.err_code} errorText={errorText} />
    </>
  )
}

const StyledForm = styled.form<{ active?: boolean }>`
  position: relative;

  display: flex;

  align-items: center;
  gap: 20px;

  width: 100%;
  max-width: 840px;

  padding: 16px;

  border-radius: 50px;

  background-color: ${({ active }) => (active ? '#171B24' : 'black')};

  transition-property: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &::after {
    content: '';

    z-index: -1;

    position: absolute;
    left: -2px;
    top: -2px;

    width: calc(100% + 4px);
    height: calc(100% + 4px);

    border-radius: 50px;

    background-image: linear-gradient(90deg, #36e4da, #9947fc);

    box-shadow: 0px 0px 24px rgba(211, 255, 252, 0.6);
  }

  ${`@media (max-width: ${1024}px)`} {
    max-width: 320px;
    gap: 5px;
  }
`

const StyledIconWrapper = styled.span`
  position: relative;

  margin-left: 16px;

  width: 21px;
  height: 20px;
  ${`@media (max-width: ${1024}px)`} {
    margin-left: 3px;
  }
`
const StyledIcon = styled.img<{ active?: boolean }>`
  position: absolute;

  opacity: ${({ active }) => (active ? 1 : 0)};

  transition-property: opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
`

const StyledSelect = styled.select`
  border-radius: 99px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: #1f1f1f;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.5);
  width: 160px;
  height: 54px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 24px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option {
    background-color: #1f1f1f; /* 옵션의 배경 색상 */
  }
`

const StyledDropdownArrowIcon = styled.img`
  position: absolute;

  right: 10px;
  top: 50%; /* 세로 중앙 정렬을 위한 위치 조정 */
  transform: translateY(-50%);
`

const StyledInput = styled.input<{ active?: boolean }>`
  flex-grow: 1;

  font-weight: 400;
  font-size: 16px;
  padding: 16px 0px;
  background: ${({ active }) => (active ? 'var(--wp-primary,linear-gradient(90deg, #00dc82 0%, #36e4da 100%))' : 'none')};
  background-clip: ${({ active }) => (active ? 'text' : '')};
  -webkit-background-clip: ${({ active }) => (active ? 'text' : '')};
  -webkit-text-fill-color: ${({ active }) => (active ? 'transparent' : '')};
  border: none;
  outline: none;

  &::placeholder {
    color: #64748b;

    ${`@media (max-width: ${1024}px)`} {
      font-size: 14px;
    }
  }

  ${`@media (max-width: ${1024}px)`} {
    max-width: 320px;
    padding: 10px 16px;
    height: 56px;
  }
`

const StyledButton = styled.button`
  padding: 16px 32px;

  border: none;
  border-radius: 25px;
  background: linear-gradient(90deg, #9947fc 0%, rgba(153, 71, 252, 0) 110.38%);

  color: white;

  font-weight: 600;
  font-size: 18px;

  line-height: 1;
  ${`@media (max-width: ${1024}px)`} {
    display: none;
  }
`

const StyledMobileButton = styled.button`
  display: none;

  ${`@media (max-width: ${1024}px)`} {
    display: block;
    padding: 16px 32px;

    border: none;
    border-radius: 25px;
    background: linear-gradient(90deg, #9947fc 0%, rgba(153, 71, 252, 0) 110.38%);

    color: white;

    font-weight: 600;
    font-size: 18px;

    line-height: 1;
  }
`

const StyledDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
`

const StyledPrivateKeyDesc = styled.div`
  color: #64748b;
  font-size: 20px;
`

const StyledTimer = styled.div`
  padding: 12px 24px;
  border-radius: 99px;
  background-color: #111;
  font-size: 16px;
  font-weight: 500;
  color: #94a3b8;
  display: flex;
  align-items: center;
  gap: 9px;
`

export default RecoveryForm
