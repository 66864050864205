import { createContext, useEffect, useState } from "react";
import { createWebSocketInstance } from "./functions";

export const SocketClientContext = createContext<any>(null);

const SocketClientProvider = ({ children }: { children: React.ReactNode }) => {
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const url = process.env.REACT_APP_WEBSOCKET_URL;

    if (!url) return;

    setSocket(createWebSocketInstance(url));
  }, []);

  return (
    <SocketClientContext.Provider value={socket}>
      {children}
    </SocketClientContext.Provider>
  );
};

export default SocketClientProvider;
