const errorMap: Record<number, string> = {
  0: 'Success.',
  1: 'Success.',
  10: 'INQUIRY SUCCESS',
  30: 'P/W RESET EMAIL HAS BEEN SENT',
  32: 'AVAILABLE ID',
  35: 'Please enter your nickname',
  101: 'This email already exists.',
  105: 'This nickname already exists.',
  107: 'The nickname contains non-English characters.',
  113: 'Please enter your password according to your criteria.',
  123: 'Password change failed. Please try again.',
  124: 'You have entered the same password.',
  129: 'The password is not valid.',
  303: 'No coin stock.',
  401: 'This phone number already exists.',
  10001: 'SQL RUN FAILURE',
  10010: "DATA DOESN'T EXIST",
  10030: 'Input value error.',
  11010: "ITEM DATA DOESN'T EXIST",
  12000: 'This is an auction sales method that is not supported.',
  12001: 'Dutch Auction Declining lowest price input error.',
  12002: 'Nft product number is incorrect.',
  12003: 'Fixed sale price entered incorrectly.',
  12004: 'Sales start date input error.',
  12005: 'Sales end date input error.',
  12006: 'Auction sales type input error.',
  12007: 'Auction sale price entered error.',
  12008: 'Auction sales type input error.',
  12009: 'Dutch Auction Declining lowest price input error.',
  12010: 'Before the sale starts.',
  12011: 'Sale has ended.',
  12012: 'Users unable to purchase.',
  12013: 'This is a purchase price input error.',
  12014: 'English Auction Sales Lowest winning bid entered error.',
  12015: 'ID that cannot request the sale of the Nft product.',
  12016: 'Bid price input error.',
  12017: 'This is an error in entering the bid end date.',
  12018: 'This is an error in entering the bid end time.',
  12019: 'You cannot bid with a coin different from the previously set coin.',
  12020: 'There are less than 24 hours left until the auction is valid. Bidding is possible only if it is 5% or more higher than the previous price.',
  12021: 'The bid number is incorrect.',
  12022: 'This product cannot be traded.',
  12023: 'Collection ID is invalid.',
  12024: 'Invalid Royalty.',
  12025: 'The owner information of the Nft item is incorrect.',
  12026: 'This is an error in entering the lowest winning bid price.',
  12027: 'Unusable NFT name.',
  12028: 'Unusable Bundle name.',
  12029: 'ITEM information cannot be modified.',
  12030: 'This nickname is already taken.', // 이 에러코드는 폰번호 중복에도, 닉네임 중복에도 쓰인다 나중에 백엔드에 바꿔달라 하기
  12037: 'External wallet address registration error.',
  12038: 'Item token address lookup error.',
  12039: 'Node sol item create_nft_transfer error',
  12040: 'Node sol item send_transaction error',
  12041: 'An external wallet address that already exists.',
  16050: 'Failed to increase the staking amount.',
  16051: 'Failed to unstake.',
  16052: 'Not enough Token for staking.',
  16053: 'Not enough synthetic assets for transaction.',
  16054: 'Failed to save the staking reward.',
  16055: 'Unable to revise or delete the information.',
  16056: 'Reward request information is not valid.',
  16057: 'Escrow information is not valid.',
  16058: 'Not enough fee. Transaction failed.',
  16059: 'Not enough tokens. Transaction failed.',
  16060: 'Input value error.',
  16061: 'There are not enough assets.',
  16062: 'Target C-Ratio is incorrect.',
  16063: 'Synthetic asset value should be greater than 0',
  16067: 'Cannot unstake more than the total staked value. Please reduce the undefined redeem amount.',
  16068: 'Cannot redeem more than total debt pool value. Please reduce the undefined redeem amount.',
  16069: 'You are not a member who signed up with MetaMask. Please try with your email login.',
  16070: 'Insufficient amount of Escrowed that can be reduced.',
  16071: "Incineration quantity is not equal to the user's debt.",
  16200: 'The coin is not available for betting.',
  16201: 'Tax administrator information is not available. Please contact the administrator.',
  16202: 'Insufficient quantity available for betting.',
  16203: 'Less than the minimum betting quantity.',
  16204: 'More than the maximum betting quantity.',
  16208: 'The maximum number of rounds has been exceeded.',
  18001: "COIN DATA DOESN'T EXIST",
  18002: "COIN DATA DOESN'T EXIST",
  18003: 'WALLET REGISTRATION FAILURE. PLEASE CONTACT CUSTOMER SERVICE',
  18004: 'Member information not found.\nPlease confirm your ID.',
  18005: 'USER DATA INQUIRY FAILURE. PLEASE CONTACT CUSTOMER SERVICE',
  18006: 'WALLET DATA INQUIRY FAILURE. PLEASE CONTACT CUSTOMER SERVICE',
  18007: "WALLET INFO DOESN'T MATCH. PLEASE CONTACT CUSTOMER SERVICE",
  19001: 'INPUT DATA ERROR',
  19005: "P/W DOESN'T MATCH",
  19006: "NEW P/W DOESN'T MATCH",
  19007: "THIS ID CAN'T BE USED",
  19008: 'MOBILE ACCESS DEACTIVATED LOG IN',
  19009: 'Renaming is not available.',
  19010: 'ISSUE SUBMITTED USER. PLEASE CONTACT CUSTOMER SERVICE',
  19011: 'You cannot use the same password as before.',
  19012: 'Username that already exists.',
  19013: 'User name change failed.',
  19015: "USER DOESN'T EXIST",
  19016: 'EXCEEDED EQUIPMENT COUNT. DELETE EXISTING EQUIPMENT TO USE',
  19017: 'EQUIPMENT NAME IN USE',
  19018: "DEVICE ID NUMBER DOESN'T EXIST",
  19019: "CAN'T LOG ON TO OVER 2 DEVICES SIMULTANEOUSLY WITH ONE ID",
  19020: 'WRONG VERIFICATION NUMBER',
  19021: 'SAME MOBILE NUMBRE EXISTS',
  19022: "USER NAME DOESN'T MATCH",
  19023: 'P/W RESET FAILED',
  19024: "USER OTP DATA DOESN'T EXIST",
  19025: 'USER OTP REGISTRATION FAILURE',
  19026: 'USER OTP USE DEACTIVATION FAILURE',
  19027: 'USER OTP SEARCH FAILURE',
  19201: 'Insufficient withdraw amount.',
  19203: 'NON TRANSACTIONABLE ITEM',
  19204: 'ACCIDENT REGISTERED ACCOUNT',
  19205: 'SALE RESTRICTED ACCOUNT',
  19206: 'BUY RESTRICTED ACCOUNT',
  19208: 'Check the order price.',
  19209: 'One-time order amount exceeded.',
  19210: 'One-time order limit exceeded',
  19211: 'Insufficient order quantity.',
  19212: 'Insufficient gasfee.',
  19301: 'INSUFFICIENT CORRECTION CANCELLATION QUANTITY',
  19302: "CAN'T MAKE CORRECTIONS ON MARKET PRICE ORDERS",
  19303: 'REVISED ORDR IS SAME TO THE ORIGINAL ORDER',
  19304: 'Invalid leverage.',
  19305: 'Leverage not available : Existing positions that have not been liquidated.',
  19306: 'Leverage not available : Existing positions that have not been liquidated.',
  19307: 'Unable to order : Incomplete liquidation order.',
  19308: 'Unable to order : Incomplete liquidation order.',
  19309: 'Have a leveraged order and cannot place an order.',
  19399: 'ERROR OCCURED DURING CONTRACT PROCESS',
  19400: 'ERROR OCCURED DURING TRANSFER',
  19401: 'TRANSACTION ERROR OCCURED DURING CONTRACT CONCLUSION',
  19402: 'ERROR OCCUERED AT MAINNET TRANSFER REQUEST',
  19403: 'ERROR OCCURED DURING MAINNET TRANSFER RESULT INQUIRY',
  19404: 'ERROR OCCURED DURING MAINNET COIN TRANSFER REPROCESSING',
  19405: 'There was no communication temporarily. Please log in again.',
  19406: "INCOMPLETE COIN TRANSER EXISTS (WITHDRAWAL CAN'T BE MADE)",
  19407: "CAN WITHDRAW AFTER SYNCHING COIN WALLET  (WITHDRAWAL CAN'T BE MADE)",
  19408: "INCOMPLETE COIN CONTRACT EXISTS (WITHDRAWAL CAN'T BE MADE)",
  19409: "FAILED COIN TRANSFER EXISTS (WITHDRAWAL CAN'T BE MADE)",
  19410: "CURRENTLY STAY PENDING FROM CURRENT TRANSACTION (WITHDRAWAL CAN'T BE MADE)",
  19411: "ADMIN WALLET DOESN'T EXIST (WITHDRAWAL CAN'T BE MADE)",
  19412: "HASH DATA OF EXISING TRANSACTION (WITHDRAWAL CAN'T BE MADE)",
  19413: 'Withdrawal is not possible.',
  19414: 'Inconsistency between canceled quantity and unfilled quantity.',
  19415: 'EXCEEDED MAX WON WITHDRAWAL REQUESTS PER DAY',
  19416: 'EXCEEDED MAX WON DEPOSIT REQUESTS PER DAY',
  19417: "THIS COIN CAN'T BE STORED",
  19418: 'LESS THAN THE LEAST AMOUNT OF STORING REQUIRED',
  19419: 'INSUFFICIENT STORABLE QUANTITY',
  19420: 'STORING SETTINGS NOT COMPLETE',
  19421: 'YOU CAN ONLY UNSTORE THE TOTAL QUANTITY',
  19422: 'STANBY TIME FROM UNSTORING TO STORING HAS NOT PASSED',
  19423: "CAN'T IEO THIS COIN",
  19424: 'INSUFFICIENT IEO AMOUNT',
  19425: 'IEO CLOSED',
  19426: 'SMALLER THAN THE LEAST ORDER AMOUNT FOR 1 IEO',
  19427: 'STANBY TIME FROM UNSTORING TO STORING HAS NOT PASSED',
  19428: "STORING IS AREADY SET (CAN'T BE ADDED)",
  19429: "THIS COIN CAN'T BE DEPOSITED",
  19430: "WON DEPOSIT ACCOUNT NUMBER DOESN'T EXIST",
  19431: "THIS COIN CAN'T BE WITHDRAWED",
  19432: "THIS IEO CAN'T BE VOTED",
  19433: 'NO VOTING HISTORY',
  19434: "DEPOSIT STANDBY COIN DOESN'T EXIST",
  19435: "LOAN REPAYMENT (WITHDRAWAL CAN'T BE MADE)",
  19436: 'WRONG IEO APPROVAL PHRASE',
  19437: "WITHDRAWAL REQUEST DATA DOESN'T EXIST",
  19438: 'PROCESS COMPLETED WITHDRAWAL DATA',
  19439: 'DEPOSIT REQUEST ALREADY EXISTS',
  19440: 'REGISTERED DATA ALREADY EXISTS',
  19441: 'FAILED TO ADD TO FAVORITES',
  19442: 'FAILED TO DELETE FAVORITES',
  19443: 'There is already data from the investment contest that you have applied for.',
  19444: 'This is a user who has not proceeded with staking lock investment.',
  19445: 'The amount of SWAP that can be applied has been exceeded.',
  19508: 'The number remaining is less than the requested number.',
  19509: 'Stored PKEY data does not exist.',
  19510: 'The OTP information is incorrect.',
  19700: 'The data could not be retrieved. Please contact the customer service.',
  19701: 'DATA SEARCH ERROR. PLEASE CONTACT CUSTOMER SERVICE',
  19702: 'FAILED TO SEND ORDER. INPUT DATA ERROR',
  19703: "FAILED TO SEND ORDER. THIS COIN CAN'T BE TRANSACTED",
  19704: "FAILED TO SEND ORDER. THIS COIN CAN'T BE TRANSACTED",
  19705: 'DATA SEARCH ERROR. PLEASE CONTACT CUSTOMER SERVICE',
  19706: 'ORDER DATA SEND FAIL',
  19707: 'FAILED TO REFRESEH ORDER DATA',
  19708: 'ORDER DATA REFRESH FAIL ',
  19709: 'ORDER DATA SEND FAIL. INPUT DATA ERROR',
  19710: 'FAILED TO REFRESEH ORDER DATA',
  19711: 'FAILED TO REFRESEH ORDER DATA',
  19712: 'FAILED TO REFRESEH ORDER DATA',
  19713: 'FAILED TO REFRESEH ORDER DATA',
  19714: 'FAILED TO REFRESEH ORDER DATA',
  19715: 'FAILED TO REFRESEH ORDER DATA',
  19716: 'FAILED TO REFRESEH ORDER DATA',
  19717: 'FAILED TO REFRESEH ORDER DATA',
  19718: 'FAILED TO REFRESEH ORDER DATA',
  19719: 'FAILED TO REFRESEH ORDER DATA',
  19720: 'FAILED TO REFRESEH ORDER DATA',
  19721: 'FAILED TO REFRESEH ORDER DATA',
  19722: 'FAILED TO REFRESEH ORDER DATA',
  19723: 'FAILED TO REFRESEH ORDER DATA',
  19724: 'FAILED TO REFRESEH ORDER DATA',
  19725: 'FAILED TO REFRESEH ORDER DATA',
  19726: 'FAILED TO REFRESEH ORDER DATA',
  19727: 'FAILED TO REFRESEH ORDER DATA',
  19728: 'FAILED TO REFRESEH ORDER DATA',
  19729: 'FAILED TO REFRESEH ORDER DATA',
  19730: 'FAILED TO REFRESEH ORDER DATA',
  19731: 'FAILED TO REFRESEH ORDER DATA',
  19732: 'FAILED TO REFRESEH ORDER DATA',
  19733: 'FAILED TO REFRESEH ORDER DATA',
  19734: 'FAILED TO REFRESEH ORDER DATA',
  19735: "DIDN'T REACH THE MATCHING SYSTEM. PLEASE TRY A LITTLE LATER",
  19736: 'FAILED TO REFRESEH ORDER DATA',
  19737: 'FAILED TO REFRESEH ORDER DATA',
  19738: 'FAILED TO REFRESEH ORDER DATA',
  19739: 'FAILED TO REFRESEH ORDER DATA',
  19740: 'FAILED TO REFRESEH ORDER DATA',
  19741: 'FAILED TO ADD USER INFO',
  19742: 'NO COIN INFO IN YOUR BALANCE',
  19743: "ADDITIONAL FEE & AIRDROP CAN'T BE CALCULATED WITH THIS COIN. PLEASE CHECK THE PURCHASE FEE CLASSIFICATION",
  19744: 'FAILED TO INCREASE BALANCE',
  19745: 'EVENT INFO FOUND IN THE CATEGORY. DELETE EVENT THEN DELETE CATEGORY',
  19746: "CATEGORY INFO DOESN'T EXIST",
  19747: 'ONLY ONE PRIZE CAN BE REDEEMED',
  19748: 'SAME COIN CODE EXISTS IN THE CATEGORY',
  19749: 'CATEGORY WITH DUPLICATED EVENT PERIOD EXISTS',
  19750: 'FAILED TO REDUCE BALANCE',
  19754: 'You have entered an incorrect bid gap.',
  19766: 'The swap request quantity exceeds the issue amount.',
  19999: 'system error : contact admin.',
  201: `You don't have a registered ID.`,
  202: 'Password or Login key does not match.',
  130: 'User information not found',
  138: 'Verification code not match',
  [-1]: 'ABNORMAL APPROACH',
  [-2]: 'Suppressed Email',
  [-3]: 'You have exceeded the number of times you can authenticate. Please try again after UTC+0.',
  [-4]: 'Failed to send Email',
  [-5]: 'Certification Failed.',
  [-7]: 'Duplicate login status. Pressing the Confirm button will log out.',
  [-8]: 'Unable to add a browser tab.',
  [-12]: 'A verification number has already been sent. The verification number is valid for 5 minutes.',
  16044: '',
  16045: '',
  16046: '',
  '-6': '',
}

export default errorMap
