import styled from '@emotion/styled'
import Typography from '../Typography'
import ProcedureCard, { TProcedureCardProps } from './Card'

const cards: Array<Omit<TProcedureCardProps, 'index'>> = [
  {
    title: (
      <>
        Apply for
        <br />
        private key recovery
      </>
    ),
    text: `Sign up on service and enter the security key you were given.`,
    iconSrc: '/img/icon_image.png',
  },
  {
    title: (
      <>
        Account
        <br />
        verification process
      </>
    ),
    text: `Verify that the information you provided is the account you have with service.`,
    iconSrc: '/img/icon_image-1.png',
  },
  {
    title: (
      <>
        Security key
        <br />
        recovery complete
      </>
    ),
    text: `Once your account information is verified, the security key recovery (private key) operation is complete.`,
    iconSrc: '/img/icon_image-2.png',
  },
]

const Procedure = () => {
  return (
    <StyledSection id="section_procedure">
      <article>
        <Typography.Title>
          Private Key Recovery <Typography.HighlightText>Procedure</Typography.HighlightText>
        </Typography.Title>
        <Typography.Paragraph>Private key recovery is made even more secure by our monitoring system.</Typography.Paragraph>
      </article>
      <StyledCardWrapper>
        {cards.map((item, index) => (
          <ProcedureCard //
            key={item.iconSrc}
            index={index + 1}
            title={item.title}
            text={item.text}
            iconSrc={item.iconSrc}
          />
        ))}
      </StyledCardWrapper>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 72px;

  width: 100%;
  max-width: 1200px;

  margin: 0 auto;
  padding: 120px 0;

  text-align: center;
`

const StyledCardWrapper = styled.div`
  display: flex;
  gap: 26px;

  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }

  ${`@media (max-width: ${1024}px)`} {
    flex-direction: column;
  }
`

export default Procedure
