import './App.css'
import styled from '@emotion/styled'
import Banner from './components/Banner'
import Recovery from './components/Recovery'
import Procedure from './components/Procedure'
import Precautions from './components/Precautions'
import Footer from './components/layouts/Footer'
import AppBar from './components/layouts/AppBar'
import ImageBackground from './components/common/ImageBackground'
import { ChakraProvider } from '@chakra-ui/react'
import { customTheme } from './styles/theme'

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <StyledWrapper>
        <AppBar />
        <ImageBackground src="/img/background_01.png">
          <Banner />
          <Recovery />
        </ImageBackground>
        <ImageBackground src="/img/background_02.png">
          <Procedure />
        </ImageBackground>
        <ImageBackground src="/img/background_03.png">
          <Precautions />
        </ImageBackground>
        <Footer />
      </StyledWrapper>
    </ChakraProvider>
  )
}

const StyledWrapper = styled.div`
  /* margin: 0 24px; */
  ${`@media (max-width: ${1024}px)`} {
    margin: 0 24px;
  }
`

export default App
